/* StepperComponent.css */
.fade-enter {
    opacity: 0;
    transform: translateX(-20px);
  }
  .fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
  }
  
  .fade-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .fade-exit-active {
    opacity: 0;
    transform: translateX(20px);
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
  }
  


/* StepperComponent.css */

/* Stepper Container */
.stepper-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
  }
  
  .stepper-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    margin-top: 30px;
  }
  
  /* Stepper Circle Style */
  .stepper-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e9ecef;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #6c757d;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .stepper-circle.active {
    /* background-color: #007bff; */
    color: #fff;
    background: rgb(0 215 125);
  }
  
  /* Stepper Line */
  .stepper-line {
    height: 2px;
    background-color: #e9ecef;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 0;
  }
  
  .stepper-line.active {
    background: rgb(0 215 125);
  }
  
  /* Fade Animation */
  .fade-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
  }
  
  .fade-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .fade-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 500ms, transform 500ms;
  }
  

  .card{
    background: #ffffff21 !important;
    color: white !important;
  }
  .divider {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
    height: 2px;
    background: linear-gradient(30deg, #0b0c0e 20%, #008346, #fff8e9, #0b0c0e 80%);
}
.yellow_btn{
    background: linear-gradient(109.8deg, rgb(255 140 18) 5.6%, rgb(255 181 32) 91.5%);
    padding: 9px 25px;
    color: #000000;
    border-radius: 50px;
    text-decoration: none;
    display: inline-block;
    font-weight: 600;
}